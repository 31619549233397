import React from "react";
import Button from "../../../utils/forms/Button";
import { showModal } from "../HomeAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createBooking } from "../../bookings/BookingsAction";
const Paynow = () => {
  const { price, useWallet } = useSelector((state) => state.BookingsState);
  const { uuid } = useSelector((state) => state.UserState);
  const { serviceUuid, date, timeSlotUuid } = useSelector(
    (state) => state.BookingsState
  );
  const dispatch = useDispatch();
  const handleBooking = () => {
    if (!uuid) {
      dispatch(showModal("home-login"));
      return;
    }
    dispatch(
      createBooking({
        serviceUuid,
        userUuid: uuid,
        timeSlotUuid,
        isWallet: useWallet,
        date,
      })
    );
  };
  return (
    <div className="bg-white pay-now  ">
      <Button
        className={"bg-blue-211 white w-fit round-10  border-transparent"}
        disabled={
          !(serviceUuid && date && (price || price === 0) && timeSlotUuid)
        }
        onClick={handleBooking}
      >
        Pay Now
      </Button>
    </div>
  );
};

export default Paynow;
