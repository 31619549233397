import React from "react";

const BookingCards = ({
  name = "",
  tip = <></>,
  date = "",
  time = "",
  action = <></>,
}) => {
  return (
    <div
      style={{
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
        padding: "0 1.5rem",
        paddingLeft: "2.44rem",

        margin: "1rem 0",
        width: "99%",
      }}
      className="h-6 booking-card-main flex align-center justify-content-between gap-md"
    >
      <div className="flex gap-md content-wrapper">
        <div className="gap-lg flex align-center">
          <div className="montserrat-medium f-s-16 main-content">
            <div className="f-s-12 grey-808 montserrat-semibold">SERVICE</div>
            {name}
          </div>
          <div>{tip && tip}</div>
        </div>
        <div className="gap-lg flex align-center">
          <div className="montserrat-medium f-s-16 montserrat-semibold">
            <div className="f-s-12 grey-808">DATE</div>
            {date}
          </div>
          <div className="montserrat-medium f-s-16 montserrat-semibold">
            <div className="f-s-12 grey-808">TIME SLOT</div>
            {time}
          </div>
        </div>
      </div>
      <div className="action-wrapper">{action && action}</div>
    </div>
  );
};

export default BookingCards;
